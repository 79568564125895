<template id="app">
  <v-app>
    <v-main class="bg">
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="pa-0">
          <img class="ml-7" src="./assets/loomen_logo.svg">
        </v-col>
        <v-col cols="12" class="d-flex flex-column align-center pa-0">
          <v-card class="text_choose_card flat tile" style="padding-left: 60px; padding-right: 60px;border-radius: 10px;background-color: #FFFFFFcc">
            <p class="text_choose font-weight-medium pt-2 mb-2">Izaberite svoj Loomen</p>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="mx-15">
<!--        <v-col v-if="!$vuetify.breakpoint.mdAndDown" lg="1"></v-col>-->
        <v-col sm="6" md="6" lg="3">
          <v-row class="pl-50">
            <v-col cols="12" class="d-flex flex-column align-center justify-center">
              <v-img
                  style="height: 220px;width:220px;border-radius: 35px;"
                  src="./assets/skole.png"
              >

              </v-img>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn
                    class="text-none"
                    rounded
                    color="#39B54A"
                    dark
                    style="width: 220px;border-radius: 35px"
                    height="52px"
                    href="https://skole.loomen.carnet.hr/"
                >
                  <span class="text_instances">Škole</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="6" md="6" lg="3">
          <v-row>
            <v-col cols="12" class="d-flex flex-column align-center justify-center">
              <v-img
                  style="height:220px;width: 220px;border-radius: 35px;"
                  src="./assets/fakulteti.png"
              >
              </v-img>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn
                    class="text-none"
                    color="#AE519F"
                    dark
                    style="width: 220px;border-radius: 35px"
                    height="52px"
                    href="https://fakulteti.loomen.carnet.hr/"
                >
                  <span class="text_instances">Fakulteti</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="6" md="6" lg="3">
          <v-row>
            <v-col cols="12" class="d-flex flex-column align-center justify-center">
              <v-img
                  style="height: 220px;width: 220px;border-radius: 35px;"
                  src="./assets/natjecanja.png"
              >
              </v-img>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn
                    class="text-none"
                    rounded
                    color="#1C75BC"
                    dark
                    height="52px"
                    style="width: 220px;border-radius: 35px"
                    href="https://natjecanja.loomen.carnet.hr/ "
                >
                  <span class="text_instances"> Natjecanja </span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col sm="6" md="6" lg="3">
          <v-row>
            <v-col cols="12" class="d-flex flex-column align-center justify-center">
              <v-img
                  style="height: 220px;width: 220px;border-radius: 35px;"
                  src="./assets/usavrsavanja.png"
              >
              </v-img>
            </v-col>
            <v-col cols="12">
              <div class="text-center">
                <v-btn
                    class="text-none"
                    rounded
                    color="#6D6D71"
                    dark
                    style="width: 220px;border-radius: 35px"
                    height="52px"
                    href="https://usavrsavanje.loomen.carnet.hr/ "
                >
                  <span class="text_instances">Stručna usavršavanja</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-col>
<!--        <v-col v-if="!$vuetify.breakpoint.mdAndDown" lg="1"></v-col>-->
      </v-row>
      <br>
      <br>

      <v-row align="center" justify="center" class="ma-0">
        <v-col cols="9" class="d-flex flex-column align-center">
          <v-card class="" style="
                            background-color: rgba(255, 255, 255, 0.8);
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                            border-radius: 15px;
                           ">
            <v-card-title class="important_notices pb-2">Važne obavijesti</v-card-title>
            <v-card-text>
              <v-row class="pl-3">
<!--                <v-col cols="2">-->
<!--                  <v-img height="65px" width="65px"-->
<!--                         src="./assets/obavijesti.svg"-->
<!--                         style="margin-bottom: 20px"-->
<!--                  ></v-img>-->
<!--                </v-col>-->
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" class="notices_title pl-0">
                      Na naslovnoj stranici novog Loomena odaberite kamo želite ići: na škole, fakultete, natjecanja ili stručna usavršavanja.
                      Svi tečajevi koje ste kreirali i na kojima ste bili unatrag godinu prebačeni su ovdje i dostupni pod „Moji e-kolegiji“. Novi Loomen je na Moodle-u 4.0.
                    </v-col>
                    <v-col cols="12" class="notices_desc pl-0 mt-0">
                      Ponedjeljak, 5. rujna 2022. godine
                    </v-col>
                  </v-row>
                </v-col>
<!--                <v-col cols="3" class="text-right">-->
<!--                  <v-btn-->
<!--                      class="text-none"-->
<!--                      style="background: rgba(105, 80, 161, 0.2); border-radius: 10px;"-->
<!--                  >-->
<!--                    <span style="color: #6950A1">Otvori</span>-->
<!--                  </v-btn>-->
<!--                </v-col>-->
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <br>
      <br>
      <br>
      <br>


    </v-main>
    <v-footer :fixed="$vuetify.breakpoint.mdAndDown ? false : true" style="bottom: 0">
      <v-row>
      <v-col class="text-left" cols="12" xs="12" sm="12" md="12" lg="6"
      >
        <span class="text_footer grey--text"> Projekt sufinancirala Europska unija iz europskih strukturnih i investicijskih
          fondova. Više informacija
          o EU fondovima možete naći na web stranicama Ministarstva regionalnoga razvoja i fondova
          Europske
          unije: www.strukturnifondovi.hr</span>
      </v-col>
      <v-col cols="4" xs="4" sm="2" md="2" lg="1" class="d-flex align-center justify-center">
        <v-img
            src="./assets/eskole.png"
        >

        </v-img>
      </v-col>
      <v-col cols="4" xs="4" sm="2" md="2" lg="1" class="d-flex flex-column align-center justify-center">
        <v-img
            height="50.7px"
            width="48.32px"
            src="./assets/esf.png"
        >

        </v-img>
      </v-col>
      <v-col cols="4" xs="4" sm="2" md="2" lg="1" class="d-flex align-center justify-center">
        <v-img
            src="./assets/opkk.png"
        >

        </v-img>
      </v-col>
      <v-col cols="4" xs="4" sm="2" md="2" lg="1" class="d-flex align-center justify-center">
        <v-img
            src="./assets/carnet.png"
        >

        </v-img>
      </v-col>
      <v-col cols="4" xs="4" sm="2" md="2" lg="1" class="d-flex align-center justify-center">
        <v-img
            src="./assets/esif.png"
        >

        </v-img>
      </v-col>
      <v-col cols="4" xs="4" sm="2" md="2" lg="1" class="d-flex align-center justify-center">
        <v-img
            src="./assets/eu.png"
        >
        </v-img>
      </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
@font-face {
  /* where FontName and fontname represents the name of the font you want to add */
  font-family: 'Camber';

  src: url("/fonts/Camber.otf") format('opentype');
}

.text_footer {
  font-family: 'Camber';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #748187;
}

.text_choose {
  font-family: 'Camber';
  font-style: normal;
  font-size: 1.2em;
  line-height: 40px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #383838;
}

.text_choose_card {
  max-width: 660px;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.important_notices {
  font-family: 'Camber';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 40px;
  color: #6D6E71;
}

.notices_title {
  font-family: 'Camber';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  color: #6D6E71;
}

.notices_desc {
  font-family: 'Camber';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(133, 133, 136, 0.86);
}

.bg {

  background-image: url("./assets/pozadina.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.text_instances {
  font-family: 'Camber';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2px;
  color: #FFFFFF;
}

</style>
